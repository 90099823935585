
import Vue from 'vue';
import QrcodeVue from 'qrcode.vue';
import { eModeType } from '@/enums';
import validationRules from '@/validation-rules';
import { sitchClientUrl } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { getParameterByName, isAtMaxModes } from '@/util-functions/misc-utils';
import { showConfirmation } from '@/util-functions/notice-utils';
import { updateUserModeGatewayDoc, copyText } from '@/util-functions/user-utils';

export default Vue.extend({
  data(): {
    rules: typeof validationRules;
    showQrCodeForPrint: boolean;
    includeFixedAmount: boolean;
    useSitchLink: boolean;
    addMessageForPrint: boolean;
    modeHasAmountProperty: boolean;
    qrData: string;
    queryStringModeId: string;
    qrPrintMessage: string;
    amount: number;
  } {
    return {
      rules: validationRules,
      showQrCodeForPrint: false,
      includeFixedAmount: false,
      useSitchLink: false,
      addMessageForPrint: false,
      modeHasAmountProperty: false,
      qrData: '',
      queryStringModeId: '',
      qrPrintMessage: t.scanHere,
      amount: 0,
    };
  },
  components: {
    QrcodeVue,
  },
  computed: {
    isSitchLinkActivated(): boolean {
      return this.$store.state.currUserModeGateway.isSitchLinkActivated;
    },
    currMode(): AnyMode {
      return this.queryStringModeId ? this.$store.state.modes[this.queryStringModeId] : this.$store.getters.currMode;
    },
    modeLink(): string {
      // Handle link based active mode ids.
      const activeModeId = this.$store.state.currUserModeGateway.activeModeId;
      if (this.$store.state.currUserModeGateway.activeModeId.includes(sitchClientUrl)) {
        return activeModeId;
      }

      const mode: any = this.currMode;
      let ret = mode.linkId ? `${sitchClientUrl}/s/${mode.linkId}` : `${sitchClientUrl}/?u=${this.$store.state.userId}&am=${mode.docId}`;
      if (this.useSitchLink) {
        ret = `${sitchClientUrl}/?u=${this.$store.state.userId}`;
      }
      if (this.includeFixedAmount) {
        const amount = this.amount || 0;
        const alreadyHasQueryString = ret.includes('?');
        return amount ? (alreadyHasQueryString ? `${ret}&amount=${amount}` : `${ret}?amount=${amount}`) : ret;
      }
      return ret;
    },
  },
  watch: {
    currMode() {
      this.onLoad();
    },
  },
  mounted() {
    this.queryStringModeId = getParameterByName('id');
    const modeShortCut = getParameterByName('msc');
    if (modeShortCut) {
      let modeId = '';
      switch (Number(modeShortCut)) {
        case 1:
          modeId = this.$store.state.currUser.modeShortCut1;
          break;
        case 2:
          modeId = this.$store.state.currUser.modeShortCut2;
          break;
      }
      if (modeId) {
        const newActiveMode = this.$store.state.modes[modeId];
        updateUserModeGatewayDoc({
          activeModeId: modeId,
          activeModeLinkId: newActiveMode.linkId,
          activeModeOwnerId: '',
        });
      } else {
        showConfirmation(t.youHaveNotSetThisShortcut, () => {
          this.$router.push({ path: '/Shortcuts' });
        });
      }
    }
    this.onLoad();
  },
  methods: {
    copyText(str: string) {
      return copyText(str);
    },
    getQrValue(forPrinting = false): string {
      let currMode: AnyMode = this.currMode;

      if (!currMode) {
        return '';
      }

      // Handle link based active mode ids.
      const activeModeId = this.$store.state.currUserModeGateway.activeModeId;
      if (this.$store.state.currUserModeGateway.activeModeId.includes(sitchClientUrl)) {
        return activeModeId;
      }

      if (this.useSitchLink) {
        return `${sitchClientUrl}/?u=${this.$store.state.userId}`;
      } else {
        switch (currMode.type) {
          case eModeType.urlRedirect:
            currMode = currMode as UrlRedirectMode;
            return `${currMode.redirectUrl}`;
          case eModeType.wifi:
            currMode = currMode as WifiMode;
            return `WIFI:S:${currMode.ssid};T:${currMode.wifiEncryption};P:${currMode.wifiPassword};`;
          default: {
            const alreadyHasQueryString = this.modeLink.includes('?');
            if (forPrinting) {
              return this.modeLink;
            }
            return alreadyHasQueryString ? `${this.modeLink}&iaqrc=true` : `${this.modeLink}/?iaqrc=true`;
          }
        }
      }
    },
    onLoad() {
      const mode: any = this.currMode;
      if (!mode) {
        return;
      }
      if (Object.prototype.hasOwnProperty.call(mode, 'amount')) {
        this.modeHasAmountProperty = true;
        this.amount = mode.amount;
      }
      this.setQrData();
    },
    createNewMode() {
      if (!isAtMaxModes()) {
        this.$router.push({ path: '/SitchForm' });
      }
    },
    printQrCode() {
      this.showQrCodeForPrint = true;
      window.setTimeout(() => {
        window.print();
        this.showQrCodeForPrint = false;
      }, 100); // Painting of the QR code appears to be async but there's no callbacks we can hook into to detect completion, so use a high delay instead.
    },
    setQrData() {
      const qrCode: any = this.$refs.qrCodeForPrint;
      if (!qrCode) {
        return;
      }
      const canvasEl: HTMLCanvasElement = (qrCode.$el as HTMLDivElement).getElementsByTagName('canvas')[0];
      if (!canvasEl) {
        showError(`Could not find the canvas element`, null, true);
        return;
      }
      var image = canvasEl.toDataURL('image/png');
      this.qrData = image;
    },
  },
});
